<template>
  <ion-page>
    <Header id="top"></Header>
    <ion-content :fullscreen="true">

      <section id="cabecera">
        <div class="container">
          <h1 class="section-title"><text v-if="!empty">Resultados de </text><text v-if="empty">No se encontraron resultados de </text><strong>"{{ this.$route.query.s }}"</strong></h1>
        </div>
      </section>

      <section class="md-section" id="products" style="padding-bottom: 0 !important;">
        <div class="container">

          <ion-grid>
            <ion-row>
              <ion-col size="6" size-xl="3" v-for="product in visibleProducts" :key="product.id">
                <Product :data="product"></Product>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="12">
                <ion-infinite-scroll
                  @ionInfinite="loadMore($event)" 
                  threshold="100px" 
                  id="infinite-scroll"
                >
                  <ion-infinite-scroll-content
                    loading-spinner="circular"
                    loading-text="Cargando más productos...">
                  </ion-infinite-scroll-content>
                </ion-infinite-scroll>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
      </section>

      <Footer></Footer>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage } from '@ionic/vue';
import { defineComponent } from 'vue';
import { funnelOutline } from 'ionicons/icons';

import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import { mapState } from 'vuex';

import {getSearchProducts} from '@/store/firestoreActions';
import Product from '@/components/Product.vue';

export default defineComponent({
  name: 'SearchResults',
  components: {
    IonContent,
    IonPage,
    Header,
    Footer,
    Product
  },
  data() {
    return {
      category: null,
      subcategory: null,
      order: null,
      filters: [],
      productsList: [],
      visibleProducts: [0,0,0,0,0,0,0,0],
      empty: false,
    }
  },
  computed: {
    ...mapState(['products'])
  },
  methods: {
    loadMore(ev){
      if(this.visibleProducts.length < this.productsList.length){
        const newProducts = this.productsList.slice(this.visibleProducts.length, (this.visibleProducts.length + 32));
        this.visibleProducts.push(...newProducts);
      }

      ev.target.complete();
    },
  },
  beforeMount(){
    getSearchProducts(this.$route.query.s).then((res) => {
      if(res.length <= 0){
        this.empty = true;

        getSearchProducts('').then((res) => {
          this.productsList = res.filter(x => x.active === true);
          this.visibleProducts = this.productsList.slice(0, 32);
        });
      }
      this.productsList = res.filter(x => x.active === true);
      this.visibleProducts = this.productsList.slice(0, 32);
    });
  },
  watch:{
    $route (to, from){
      if(to.name === "Search Results"){
        const element = document.getElementById('top');
        element.scrollIntoView()

        getSearchProducts(this.$route.query.s).then((res) => {
          if(res.length <= 0){
            this.empty = true;

            getSearchProducts('').then((res) => {
              this.productsList = res.filter(x => x.active === true);
              this.visibleProducts = this.productsList.slice(0, 32);
            });
          }
          this.productsList = res.filter(x => x.active === true);
          this.visibleProducts = this.productsList.slice(0, 32);
        });
      }
    }
  },
    setup() {
    return {
      funnelOutline,
    }
  }
});
</script>

<style scoped>
  #cabecera{
    margin-top: 4rem;
  }

  .section-title{
    margin-bottom: 0 !important;
  }

  .sorter{
    background: var(--ion-color-light-shade);
    color: var(--ion-color-dark);
    width: calc(100% - 1rem);
    --padding-end: 16px;
    margin: 0.5rem;
    float: left;
  }

  .filter{
    --background: var(--ion-color-light-shade);
    --color: var(--ion-color-medium);
    width: calc(100% - 1rem);
    height: 45px;
    margin: 0.5rem;
    float: right;
  }

  .filter-sorter{
    overflow: hidden;
    margin-bottom: 1rem;
  }

  @media (min-width: 960px) {
    .sorter{
      width: calc(50% - 1rem);
    }

    .filter{
      width: calc(50% - 1rem);
    }
  }
</style>
